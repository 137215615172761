@import "~antd/dist/antd.less";

html,
body {
  height: 100vh;
  margin: 0px;
  padding: 0px;
  // font-family: Arial, Helvetica, sans-serif;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    //background: #fff;
    background: rgba(150, 150, 150, 0.3);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    //background: #888;
    background: rgba(170, 170, 170, 0.8);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    //background: #555;
  }
}

a {
  color: black;
}

a:hover {
  color: inherit;
}

a:visited {
  color: inherit;
}

.App {
  height: 100vh;
  // width: 100vw;
}

.default-app-container {
  padding-top: 50px;
  height: 100vh;
  // width: 100%;
}

.app-container {
  height: 100vh;
  // width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  color: inherit;
}

.h4 {
  font-size: 24px;
  margin: 20px;
}

@primary-color: #3d8dd9;@menu-dark-bg: #121212;@white: #fff;@black: #000;