.tox-tinymce--disabled {
  border: none !important;
}
.ed-wrapper .ant-select-selector {
  padding: 0px !important;
  height: 24px !important;
  border: 1px solid blue;
}
.ed-wrapper
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search {
  left: 0px !important;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: rgb(205, 205, 205);
  // font-style: italic;
  font-size: 14px;
  margin-left: 12px;
  font-family: Helvetica, Arial, sans-serif;
}

.ed-editor ol {
  list-style-position: inside;
  padding-left: 10px;
}
.ed-editor .ol-lower-alpha {
  list-style-type: lower-alpha;
}
.ed-editor .ol-upper-alpha {
  list-style-type: upper-alpha;
}
.ed-editor .ol-lower-roman {
  list-style-type: lower-roman;
}
.ed-editor .ol-upper-roman {
  list-style-type: upper-roman;
}
.ed-editor ul {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 10px;
}
.ed-editor .ul-square {
  list-style-type: square;
}
.ed-editor .ul-circle {
  list-style-type: circle;
}

.ed-tag {
  display: inline-block;
}

.ed-columns {
  position: relative;
  z-index: 1;
}

.ed-blockquote blockquote {
  font-style: italic;
}

.ed-code-snippet pre {
  padding: 0px;
}
code > div {
  padding: 10px;
}

.ed-editor hr {
  height: 2px;
  background-color: #ededed;
}
.ed-editor > div {
  position: relative;
}

// Image styles
.ed-image-container {
  // resize: both;
  overflow: auto;
  // height: 100px;
  width: fit-content;
  // padding: 5px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
  position: relative;
}
#ed-editor-id:read-write .ed-image-container:hover {
  // #ed-editor-id[disabled]:not([disabled="true"]) .ed-image-container:hover {
  // .ed-image-container:hover {
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
}
#ed-editor-id:read-write .ed-image-container:hover .element-delete-btn {
  // #ed-editor-id[disabled]:not([disabled="true"])
  //   .ed-image-container:hover
  //   .element-delete-btn {
  // .ed-image-container:hover .element-delete-btn {
  display: inline-block;
}
#ed-editor-id:read-write .ed-img:focus ~ .element-delete-btn {
  // #ed-editor-id[disabled]:not([disabled="true"])
  //   .ed-img:focus
  //   ~ .element-delete-btn {
  // .ed-img:focus ~ .element-delete-btn {
  display: inline-block;
}
.ed-image-container > img {
  // height: inherit;
}

.ed-img {
  // height: inherit;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  margin: 5px;
  // border: 1px solid blue;
}
.ed-action-item {
  padding-left: 5px;
}

.ed-action-item:focus {
  border: 1px solid #d1d1d1;
}
.ed-action-item:hover {
  border: 1px solid #d1d1d1;
}
// .ed-image-container:focus-within::after {
//   pointer-events: none;
//   content: "↓";
//   font-size: 14px;
//   position: absolute;
//   height: 22px;
//   width: 20px;
//   text-align: center;
//   bottom: 2px;
//   right: -2px;
//   background-color: #efefef;
//   color: #777;
// }

// #ed-editor-id[disabled="false"] .message:hover .element-delete-btn {
//   display: inline-block;
//   top: -10px;
// }
// #ed-editor-id[disabled="false"] .message:focus-within ~ .element-delete-btn {
//   display: inline-block;
// }
