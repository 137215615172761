.landing-container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 10px 0px;
  // border: 1px solid red;
}
.landing-container div {
  box-sizing: border-box;
  // border: 1px solid blue;
}

.landing-top {
  background-image: url("https://cdn.notebuq.com/file/ntbq-01/resources/landing/ltm1.jpg");
  //\ background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
  //   url("../../../assets/landing-title-bg.jpg");
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 750px;
  width: inherit;
  margin-top: -50px;
}

.landing-bottom {
  background-image: url("https://cdn.notebuq.com/file/ntbq-01/resources/landing/landing-bottom.jpg");
  background-position: 0% 30%;
  position: relative;
  // background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  width: inherit;
}

.landing-footer-column {
  // border: 1px solid rgb(198, 204, 215);
  padding: 20px;
  line-height: 1.8;
}

.landing-footer-column-heading {
  font-size: 18px;
}
.landing-footer-column-list-container {
  // padding: 20px;
}
.landing-footer-column-listItem {
  font-size: 12px;
}
.landing-footer-column-listItem a {
  color: inherit;
}

@media only screen and (max-width: 900px) {
  .landing-top {
    background-position: 20% 0%;
  }
  .landing-bottom {
    height: 150px;
  }
}

@primary-color: #3d8dd9;@menu-dark-bg: #121212;@white: #fff;@black: #000;