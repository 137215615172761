.explore-search-container .ant-select-selector {
  /* height: 50px !important; */
  border-radius: 5px !important;
  /* font-size: 20px !important; */
  /* padding-top: 3px !important; */
}

.explore-trending-topics-container > a {
  /* height: 60px; */
  border: 2px solid rgba(0, 0, 0, 0);
}
.explore-trending-topics-container > div:hover {
  /* height: 60px; */
  border-radius: 55px;
  border: 2px solid #d2d2d2;
}

.explore-trending-topics-container a:hover {
  color: inherit;
  border: 2px solid #d2d2d2;
}
.notebook-item a:hover {
  color: inherit;
}

.feed-note-item-description::selection {
  color: #ddd;
}
