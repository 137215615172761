.solutions-category-item-title a {
  position: relative;
}
.solutions-category-item-title a:hover {
  color: #222;
}
.solutions-category-item-title a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #5d90c0;
  //   background-color: #000;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.solutions-category-item-title a:hover:before {
  visibility: visible;
  width: 100%;
}

.solutions-detail-info-item {
  font-size: 24px;
  margin: 40px 20px;
  display: grid;
  // text-align: right;
  // grid-template-columns: 1fr 2fr;
  grid-gap: 25px 50px;
  // height: 200px;
  padding: 20px;
  // padding-left: 100px;
  // border: 2px solid @primary-color;
  border-radius: 15px;
  text-align: center;
  background-color: aliceblue;
  font-weight: 500;
  // font-family: Helvetica, Arial, sans-serif;

  //   justify-items: center;
}

.solutions-detail-info-item div {
  //   border: 1px solid red;
}

.solutions-detail-info-item-title {
}

.solutions-detail-info-item-details {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  width: 50%;
  // margin-top: 35px;
  margin: 0 auto;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1.8;
}

@media only screen and (max-width: 901px) {
  .solutions-detail-info-item {
    font-size: 24px;
    margin-top: 50px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0px 0px;
    height: auto;
    padding-left: 0px;
    //   border: 1px solid blue;
    justify-items: center;
  }
  .solutions-detail-info-item-details {
    font-size: 18px;
    text-align: center;
    width: 80%;
    margin-top: 15px;
  }
  .solutions-detail-info-item div {
    // border: 1px solid red;
  }
}

@primary-color: #3d8dd9;@menu-dark-bg: #121212;@white: #fff;@black: #000;