.register-container {
  text-align: center;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 60px;
  border: 1px solid #e1e1e1;
  padding: 20px;
  // background-color: rgba(250, 250, 250, 0.5);
}
.registration-form {
}

.registration-form p {
  font-size: 12px;
}

p a {
  color: @primary-color;
}

.google-signup-button {
  border: 1px solid red;
}

@primary-color: #3d8dd9;@menu-dark-bg: #121212;@white: #fff;@black: #000;