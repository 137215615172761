.allnotes-listitem {
}
.dashboard-container {
  height: 100%;
  display: flex;
  align-items: stretch;
  overflow-y: auto;
}
.left-panel {
  padding-top: 40px;
  border-right: 3px solid #d1d1d1;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  overflow: auto;
  height: 100%;

  //min-width: 240px;
  /* width */
}
.left-panel::-webkit-scrollbar {
  width: 4px;
}
/* Track */
.left-panel::-webkit-scrollbar-track {
  //background: #fff;
  // background: rgba(256, 256, 256, 0);
  background: transparent;
}

/* Handle */
.left-panel::-webkit-scrollbar-thumb {
  //background: #888;
  // background: rgba(170, 170, 170, 0.5);
  // background: transparent;
  // background-color: rgba(0, 0, 0, 0.4);
  // border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10rem;
  border: 1px solid #fff;
}
.left-panel::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.left-panel::-webkit-scrollbar-track-piece:end {
  background: transparent;
}
.right-panel {
  width: 100%;
  min-width: 40%;
  height: 100%;
}

.left-panel-handle {
  background-color: rgba(256, 256, 256, 0);
  &:hover {
    background-color: #d1d1d1;
  }
}

.my-notebooks-collapse-item {
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-header {
    // background-color: #e3e3e3;
  }
}

.noteview-container {
  //padding: 0px 15px;
  // height: 100%;
  overflow: auto;
}

.noteview-container div {
  // border: 1px solid red;
}

.noteview-note-info div {
  // border: 1px solid red;
}

.noteview-header {
  height: 100%;
  // display: grid;
  // grid-auto-flow: column;
  // grid-gap: 10px;
  // align-items: center;
  // grid-template-columns: 60% auto;
  // font-size: 12px;
  // justify-content: space-between;
  overflow: auto;
}

.noteview-notebook {
  height: 100%;
}

.noteview-title {
  font-size: 40px;
  // font-weight: 500;
  // border-bottom: 1px solid #d1d1d1;
  width: 100%;
  padding: 20px;
}

.noteview-header-actions {
  // border-bottom: 1px solid #d1d1d1;
  // position: sticky;
  // position: -webkit-sticky;
  // top: 0;
  // padding: 10px 15px;
  // box-shadow: 0px 10px 5px -10px rgba(0, 0, 0, 0.75);
  // -webkit-box-shadow: 0px 10px 5px -10px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 0px 10px 5px -10px rgba(0, 0, 0, 0.75);
}

.feedleftpanel-topic-item:hover {
  background-color: aliceblue;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(150, 150, 150, 0);
  border-right: 5px solid rgba(150, 150, 150, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

@primary-color: #3d8dd9;@menu-dark-bg: #121212;@white: #fff;@black: #000;