.ed-table {
  position: relative;
}
.ed-table tbody th {
  position: relative;
  box-sizing: border-box;
}
.element-table-options {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 14px;
  border: 2px solid rgb(0, 157, 255);
  border-radius: 3px;
  width: 280px;
  //   padding: 0 50px;
  display: none;
  grid-template-columns: repeat(9, 1fr);
  //   margin: 5px;
  -webkit-user-modify: read-only;
  box-shadow: 0px 6px 22px -8px rgba(0, 0, 0, 0.45);
}

.element-table-options button {
  //   margin: 0px 10px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.element-table-options button:hover {
  color: rgb(0, 157, 255);
}

.table-action-delete-current-row,
.table-action-delete-current-column {
  border-right: 1px solid #d1d1d1;
}

.element-table-options .table-action-delete-table,
.element-table-options .table-action-delete-table:hover {
  color: rgb(194, 22, 22);
}

.ed-table:hover .element-table-options {
  display: grid;
}

.ed-table tbody:hover td,
.ed-table tbody:hover th {
  border-right: 1px solid #d1d1d1;
}
.ed-table tbody:hover td:last-child,
.ed-table tbody:hover th:last-child {
  // border-right: none;
}

.table-action-more-options {
  position: relative;
}

// Column resizer handle
.col-resizer-handle {
  /* Displayed at the right side of column */
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  cursor: col-resize;
  user-select: none;
  -webkit-user-modify: read-only;
}

.col-resizer-handle:hover,
.resizing {
  border-right: 2px solid blue;
}

// Tooltip css
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 220px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}
