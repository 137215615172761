@import url("https://fonts.googleapis.com/css2?family=Abel&family=Amiri&family=Architects+Daughter&family=Arima+Madurai&family=Bad+Script&family=Cairo&family=Caveat&family=Courgette&family=Crete+Round&family=Crimson+Text&family=Dancing+Script&family=Fauna+One&family=Fjalla+One&family=Girassol&family=Great+Vibes&family=Indie+Flower&family=Itim&family=Josefin+Sans&family=Kanit&family=Kaushan+Script&family=Lato&family=Lobster&family=Lobster+Two&family=Lora&family=Merriweather&family=Montserrat&family=Niconne&family=Noto+Sans+KR&family=Nunito&family=Nunito+Sans&family=Open+Sans&family=Open+Sans+Condensed:wght@300&family=Oswald&family=PT+Sans&family=Pacifico&family=Pangolin&family=Playfair+Display&family=Raleway&family=Roboto&family=Roboto+Condensed&family=Roboto+Mono:wght@200&family=Roboto+Slab&family=Rubik&family=Sacramento&family=Sansita&family=Source+Sans+Pro&family=Sriracha&family=Tinos&family=Ubuntu&family=Varela+Round&family=Work+Sans&display=swap");

.nav-avatarmenu-container {
  // border: 1px solid #d2d2d2;
  // width: 200px;
  // background-color: @white;
  // -webkit-box-shadow: 0px 0px 35px -10px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 0px 0px 35px -10px rgba(0, 0, 0, 0.75);
  // box-shadow: 0px 0px 35px -10px rgba(0, 0, 0, 0.75);
}
// .nav-container-guest {
//   height: 80px;
//   box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
//   position: fixed;
//   top: 0;
//   width: 100%;
//   padding: 0px 150px;
//   z-index: 10;
//   background-color: #fefefe;
//   display: grid;
//   grid-template-rows: 1fr;
//   grid-template-columns: 1fr 2fr 2fr;
//   align-items: center;
//   justify-content: space-between;
// }
.nav-container-guest div {
  // border: 1px solid red;
}

.nav-container-auth {
  // height: 60px;
  // // box-shadow: 1px 0px 4px 1px rgba(0, 0, 0, 0.3);
  // position: fixed;
  // top: 0;
  // width: 100%;
  // z-index: 10;
  // display: grid;
  // grid-gap: 20px;
  // grid-template-rows: 1fr;
  // grid-template-columns: 1fr 3fr 1fr;
  // align-items: center;
  // justify-content: space-between;
}

.nav-container-auth div {
  // border: 1px solid red;
}

.nav-menu {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  color: black;
  // padding: 10px;
  font-size: 16px;
  text-align: center;
  &:hover {
    // background: @primary-color;
    color: @primary-color;
  }
}

.nav-search {
  max-width: 500;
  justify-self: end;
}

.nav-actions {
  //border: 1px solid red;
}

.nav-logo-guest {
  font-size: 24px;
  // border: 1px solid red;
  // width: 250px;
  font-weight: 500;
  font-family: Helvetica, Arial, sans-serif;
}
.nav-logo-auth {
  // font-size: 38px;
  // border: 1px solid red;
  // width: 250px;
  font-weight: 500;
}

.landing-carousel-arrow {
  &:hover {
    color: @primary-color;
  }
}

@primary-color: #3d8dd9;@menu-dark-bg: #121212;@white: #fff;@black: #000;