.ed-expand {
  position: relative;
  z-index: 1;
}

.element-delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  display: none;
  margin: 5px;
  -webkit-user-modify: read-only;
}
.element-delete-btn:hover {
  display: inline-block;
}

.expand__details {
  list-style-type: none;
  border: 1px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
  // font-size: 16px;
}

#ed-editor-id:read-write .expand__details:hover {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
}
#ed-editor-id:read-write .expand__details:hover ~ .element-delete-btn {
  display: inline-block;
}

.expand__details:focus {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  // box-sizing: border-box;
}
#ed-editor-id:read-write .expand__details:focus ~ .element-delete-btn {
  display: inline-block;
}
details[open] {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
}

.expand__summary {
  // border-bottom: 1px solid #d1d1d1;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.expand__summary__content {
  display: inline;
}

.expand__data {
  padding: 10px 20px;
  padding-left: 30px;
}

details summary > * {
  display: inline;
}

.ed-message .message {
  font-size: 16px;
}

.ed-message .message .message-header {
  padding: 10px 15px;
}
.message-header p {
  margin-bottom: 0px;
}
