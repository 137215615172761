#tooltip {
  opacity: 0;
  z-index: 20;
  pointer-events: none;
}

.tooltip-visible {
  pointer-events: auto !important;
  animation: pop-upwards 180ms forwards linear;
  animation-delay: 0.2s;
  transition: opacity 0s linear 0.2s;
}

@keyframes pop-upwards {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.7;
  }

  40% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
