.sidebar-container {
  // height: 100vh;
  // position: fixed;
  // left: 0;
  // min-width: 200px;
  // //border: 1px solid red;
  // border-right: 1px solid #c1c1c1;
  // padding-top: 15px;
  // resize: horizontal;
  // // overflow: none;
}
.sidebar-menu-item {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  // &:hover {
  //   background-color: #f1f1f1;
  // }
  &:active {
    background-color: #d2d2d2d2;
  }
}

// .tm-editor.tox-icon.tox-tbtn__icon-wrap {
//   transform: scale(0.8);
// }

// .tm-editor.tox.tox-collection__item-checkmark,
// .tm-editor.tox.tox-collection__item-icon {
//   transform: scale(0.5);
// }
// .tm-editor.tox-tbtn {
//   transform: scale(0.5);
// }


@primary-color: #3d8dd9;@menu-dark-bg: #121212;@white: #fff;@black: #000;