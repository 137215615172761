// .editor-wrapper-toolbox {
//   padding: 5px 15px;
//   display: grid;
//   grid-auto-flow: column;
//   grid-gap: 35px;
//   align-items: center;
//   //grid-template-columns: auto;
//   font-size: 12px;
//   //justify-content: right;
//   border-bottom: 1px solid #d1d1d1;
//   //border-bottom: 1px solid #d2d2d2;
// }

// .ql-toolbar.ql-snow {
//   background-color: red !important;
//   color: blue !important;
// }

.tox-editor-header {
  z-index: 18;
}

.editor-without-toolbar {
  box-sizing: border-box;
}

.mce-content-body {
}

// .mce-content-body h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-weight: 500;
// }

@primary-color: #3d8dd9;@menu-dark-bg: #121212;@white: #fff;@black: #000;