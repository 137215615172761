@media only screen and (min-width: 901px) {
  textarea {
    resize: none;
  }
  .update-profile-wrapper {
    margin: 0px 0px;
    padding: 10px 0px;
    width: 100%;
  }

  .update-profile-container {
    background-color: #fff;
    width: 90%;
    border: 1px solid #b8b8b8;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 10px 30px;
    margin: 0 auto;
  }

  .update-profile-row {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 2fr 3fr 3fr;
  }

  .update-profile-col-account {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    padding: 0 30px;
    // margin-bottom: -30px;
    padding-top: 20px;
    max-width: 100%;
  }
  .update-profile-col-handle {
    padding: 0 30px;
    // margin-bottom: -30px;
    padding-top: 20px;
    max-width: 100%;
  }

  .update-profile-avatar-container {
    margin: 0px 50px;
  }
}

@media only screen and (max-width: 900px) {
  textarea {
    resize: none;
  }
  .update-profile-wrapper {
    margin: 80px 0px;
    width: 100%;
  }

  .update-profile-container {
    background-color: #fff;
    width: 100%;
    border: 1px solid #b8b8b8;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 30px;
  }

  .update-profile-row {
    display: grid;
    grid-gap: 20px;
    //grid-template-rows: 2fr 3fr 3fr;
  }

  .update-profile-avatar-bio-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  // .update-profile-bio {
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   word-wrap: break-word;
  //   display: block;
  //   line-height: 1em;
  //   max-height: 5em;
  // }
  .update-profile-avatar-name-breaker {
    display: none;
  }

  .update-profile-col-account {
    width: 100%;
  }

  .update-profile-col-handle {
    width: 100%;
  }
}

@primary-color: #3d8dd9;@menu-dark-bg: #121212;@white: #fff;@black: #000;